<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      {{ title }}
    </h1>

    <div class="mb-4">
      <button @click="handleToevoegen">+ {{ labelAdd }}</button>
    </div>

    <div class="flex flex-col md:flex-row justify-between gap-2">
      <UITabs :tabs="tabs" v-model="tab" />
    </div>

    <div class="py-4 inline-flex flex-col">
      <h2 class="bg-dashboard-dark text-white mb-0 px-2 py-1">Filter</h2>
      <div class="flex flex-row gap-4 px-2 mt-2">
        <div>
          <UISelectMinMax
            min="2018"
            :max="new Date().getFullYear() + 1"
            label="Jaar"
            v-model="filters.year"
          />
        </div>
        <div>
          <label for="">Month</label>
          <select name="" id="" v-model="filters.month">
            <option :value="null">Alle maanden</option>
            <option v-for="n in 12" :key="n" :value="n">{{ capitalize(monthToString(n)) }}</option>
          </select>
        </div>
        <div>
          <UISelectChauffeur class="w-full max-w-sm" active nullable label v-model="filters.user_id"/>
        </div>
      </div>
    </div>

    <div class="py-4">
      <UITableResponsive :items="items" :headers="headers">
        <template v-slot:colgroup>
          <colgroup>
            <col v-for="n in headers.length - 3" :key="n" class="w-0" />
            <col class="w-full" />
            <col class="w-0" />
          </colgroup>
        </template>
        <template v-slot:item-Chauffeur="{ item }">
            {{ store.getters.chauffeur(item.user_id)?.label }}
        </template>
        <template v-slot:item-Datums="{ item }">
          <span class="block"><b>Begin: </b>{{ dateTimeStringLocale(item.start) }}</span>
          <span class="block"><b>Einde: </b>{{ dateTimeStringLocale(item.einde) }}</span>
        </template>
        <template v-slot:item-Reden="{ item }">
          <span class="min-w-32 block font-bold">{{ item.reason }}</span>
          <ButtonAlertOpmerking v-if="item.opmerking" :opmerking="item.opmerking" />
        </template>
        <template v-slot:item-Aangemaakt_op="{ item }">
          <span class="block">
            <b>Status: </b>
            <label class="inline-block rounded px-2" :class="statusClass(item.status)">{{ item.status }}</label>
          </span>
          <span class="block"><b>Aangevraagd: </b>{{ dateTimeStringLocale(item.creation_date) }}</span>
        </template>
        <template v-slot:item-Behandelen="{ item }">
          <div class="flex flex-col gap-2 w-32">
            <button
              :disabled="item.status === 'ACCEPTED'"
              @click="onAccept(item)"
              class="btn success text-white h-8"
            >
              <i class="fas fa-check"></i> Aanvaarden
            </button>
            <button
              :disabled="item.status === 'DENIED'"
              @click="onDeny(item)"
              class="btn error text-white h-8"
            >
              <i class="fas fa-times"></i> Afwijzen
            </button>
          </div>
        </template>
        <template v-slot:item-Acties="{ item }">
          <div class="flex flex-col gap-2 w-32">
            <button
              :disabled="item.status === 'DENIED'"
              @click="handleEdit(item)"
              class="btn warning text-white h-8"
            >
              <i class="fas fa-edit"></i> Bewerken
            </button>
          </div>
        </template>
      </UITableResponsive>
      <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>

<script setup>
import clone from 'just-clone'
import { defineAsyncComponent, ref, unref, computed } from 'vue'
import { useStore } from 'vuex'

import UITableResponsive from '@/components/UI/Table/Responsive'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
import UITabs from '@/components/UI/Tabs.vue'

import { dateTimeStringLocale, monthToString } from '@/functions/formatDate'
import { capitalize } from '@/functions/formatText'
import usePaginatedApi from '@/hooks/usePaginatedApi'
import useModal from '@/hooks/useModal'
import useApi from '@/hooks/useApi'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))
const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))

const props = defineProps({
  tabel: { type: String, required: true },
  title: { type: String, required: true },
  labelAdd: { type: String, required: true },
  identifierForm: { type: String, required: true },
})

const api = useApi()
const store = useStore()
const today = new Date()

const tabs = ['Na te kijken', 'Aanvaard', 'Geweigerd']
const tab = ref(0)
const tabStatus = {
  0: 'PENDING',
  1: 'ACCEPTED',
  2: 'DENIED',
}

const isAfwezig = props.tabel === 'afwezigheden'
const isAanwezig = props.tabel === 'aanwezigheden'
const headers = computed(() => {
  if (isAfwezig) return ['Chauffeur', 'Datums', 'Aangemaakt_op', 'Reden', 'Behandelen', 'Acties']
  if (isAanwezig) return ['Chauffeur', 'Datums', 'Aangemaakt_op', 'Behandelen', 'Acties']
  return []
})

const filters = ref({
  year: today.getFullYear(),
  month: null,
  user_id: null,
})

const apiData = computed(() => ({
  tabel: props.tabel,
  ...filters.value,
  status: tabStatus[tab.value],
}))

const [openFixedModal] = useModal({ fixed: true })
const { limit, offset, count, items, getData } = usePaginatedApi('BESCHIKBAARHEID_LIST', apiData, {
  watch: true,
  defaultLimit: 25,
})

const handleToevoegen = () => {
  openFixedModal(props.identifierForm, { callback: getData })
}

const handleEdit = (item) => {
  openFixedModal(props.identifierForm, { modelValue: clone(unref(item)), callback: getData })
}

const onAccept = (item) => {
  api('BESCHIKBAARHEID_ACCEPT', { id: item.id, tabel: props.tabel }).then(() => {
    item.status = 'ACCEPTED'
  })
}

const onDeny = (item) => {
  api('BESCHIKBAARHEID_DENY', { id: item.id, tabel: props.tabel }).then(() => {
    item.status = 'DENIED'
  })
}

const statusClass = (status) => ({
  'bg-green-500 text-white': status === 'ACCEPTED',
  'bg-yellow-400 text-white': status === 'PENDING',
  'bg-red-500 text-white': status === 'DENIED',
})
</script>
